import PageHeader from "../common/PageHeader";
import {
    Card,
    Col,
    Form,
    ListGroup,
    ListGroupItem,
    Row,
    Table,
    Button,
    InputGroup,
    FormControl,
    Spinner, Alert
} from "react-bootstrap";
import AllUsersTableBody from "./AllUsersTableBody";
import FalconCardBody from "../common/FalconCardBody";
import FalconComponentCard from "../common/FalconComponentCard";
import React, {useEffect, useState} from "react";
import axios from "axios";
import TwoFactorStatusLock from "./TwoFactorStatusLock";
import IconAlert from "../common/IconAlert";
const TwoFactorAuth = (props)=>{


    let [barcode, setBarcode] = useState({
        barcode: "",
        optedIn: ""
    })
    const [code,setCode] = useState("")
    let [clickDisabled, setClickDisabled] = useState(false)
    let [alert, setAlert] = useState({
        type:"",
        message:""
    })

    useEffect( ()=>{
        axios({
            method: 'post',
            url: props.baseURL + "/2fa/get-otp-barcode",
            headers:{
                Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`
            },
        }).then( (r)=>{
            setBarcode({
                barcode: r.data.barcode,
                optedIn: r.data.optedIn
            })

        }).catch( (e)=>{
            console.log(e.response.data)
        })

    },[])
    const handleClick = ()=>{
        setClickDisabled(true)
        axios({
            method:"POST",
            url: props.baseURL + "/2fa/verify-otp",
            headers:{
                Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`
            },
            data:{
                token:code
            }
        }).then( (r)=>{
            setAlert({
                type:"success",
                message:"Successfully enabled 2FA"
            })
            setBarcode({
                ...barcode,
                optedIn: true
            })
        }).catch( (e)=>{
            setAlert(e.response.data)
            setClickDisabled(false)
        })

    }

    return (
        <>
            <PageHeader
                title="Two Factor Authentication"
                description="Secure your account with Google or Microsoft Authenticator"
                className="mb-3"
                titleTag="h2"
            >
            </PageHeader>
            <FalconComponentCard noGuttersBottom  >
                {barcode.barcode.length === 0 && <Spinner className="position-absolute start-50" animation="grow" />}
                {barcode.barcode.length > 0 && <>
                <FalconComponentCard.Body>
                    {alert.message.length > 0 && <IconAlert variant={alert.type === 'error' ? "danger" :"success"}>{alert.message}</IconAlert>}

                    <Row style={{
                        paddingBottom:"10px"
                    }}>
                        <Col className={"col d-flex justify-content-center align-items-center"}>
                            <Card style={{
                                width: '20rem'
                            }}>
                                <Card.Img src={barcode.barcode} variant='top'/>
                                <Card.Body>
                                    <Card.Title className={"d-flex justify-content-center align-items-center"} >
                                        {barcode.optedIn && <p style={{color:"green"}}>You have 2FA enabled.</p>}
                                    </Card.Title>
                                    { barcode.optedIn && <> <TwoFactorStatusLock /></>}
                                    { !barcode.optedIn && <>
                                    <Card.Text>
                                        Please scan the barcode and enter the passcode to secure your account with OTP 2FA
                                    </Card.Text>

                                    <InputGroup className="mb-3">
                                        <FormControl
                                            placeholder="123456"
                                            value={code}
                                            onChange={(e)=>{setCode(e.target.value)}}
                                        />
                                        <Button variant="outline-primary" id="button-addon2" disabled={clickDisabled} onClick={handleClick}>
                                            Secure Account
                                        </Button>

                                    </InputGroup>
                                    </>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>



                </FalconComponentCard.Body>
                    </>
                }
            </FalconComponentCard>

        </>
    )

}


export default TwoFactorAuth;