export default [
  {
    media: {
      icon: "faServer",
    },
    title: 'Server Manager GUI',
    description:
      "Indepth logs through our server manager GUI. No more having to use the command line to manage your server."
  },
  {
    media: {
      icon: "faSupport"
    },
    title: 'Live Support',
    description:
      'We offer 24/7 support to answer any questions and address any concerns you may have.'
  },
  {
    media: {
      icon: "faSecure"
      },
    title: 'Secure & Easy to Use',
    description:
      'We hide all password and PEM files from the user. We also encrypt all data sent to and from the server.'
  }
];
