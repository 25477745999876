import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PageHeader from 'components/common/PageHeader';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import PurchaseCard from "./PurchaseCard";

const Purchase = (props) => {
    const [priceLoading, setPriceLoading] = useState(true);
    const [pricing, setPricing] = useState([]);

    useEffect(() => {
        axios.get(`${props.baseURL}/checkout/get-packages`)
            .then((response) => {
                setPricing(response.data.packages);
                setPriceLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setPriceLoading(false);
            });
    }, []);

    return (
        <>
            <PageHeader
                title="Purchase ChatGPT SSH Plugin"
                description="Get the power, control, and customization your server through ChatGPT."
                className="mb-3"
                titleTag="h2"
            >
            </PageHeader>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="g-0">
                        {priceLoading ? (
                            <Col xs={12} className="py-4">
                                <Spinner
                                    className="position-absolute start-50"
                                    animation="grow"
                                />
                            </Col>
                        ) : (
                            pricing.map((pricingItem,index) => (
                                <PurchaseCard pricing={pricingItem} isFeatured={index%2 === 1} baseURL={props.baseURL} index={index} />
                            ))
                        )}
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default Purchase;
