import axios  from "axios";
import {useState} from "react";
const AdminUsersTableBody = (props)=>{

    let [users,setUsers] = useState([])

    let allUsers = axios(props.baseURL + "/admin/users",{
        headers:{
            Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`
        },
        method:"POST"
    }).then( (response)=>{
        setUsers(response.data.users)
    })


    return (
        <>
            <tbody>

                    {users.length > 1 &&
                        users.map((user,index)=>{
                            return (<tr>
                                <td>{index+1}</td>
                                <td>{user.email}</td>
                            </tr>)
                        })
                    }

            </tbody>
        </>
    )

}
export default AdminUsersTableBody