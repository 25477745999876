import PageHeader from "../common/PageHeader";
import {Alert, Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import PurchaseCard from "./PurchaseCard";
import {useRef, useState} from "react";
import axios from "axios";

const ChangePassword = (props) => {
    const currentPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmNewPasswordRef = useRef(null);
    const [disableButton, setDisableButton] = useState(true);
    const [alert, setAlert] = useState({ type: "", message: "" });
    function handleFormChange() {
        const currentPassword = currentPasswordRef.current.value;
        const newPassword = newPasswordRef.current.value;
        const confirmNewPassword = confirmNewPasswordRef.current.value;

        // Enable the submit button only if all fields are non-empty and the new passwords match
        setDisableButton(!currentPassword || !newPassword || !confirmNewPassword || newPassword !== confirmNewPassword);
    }
    function handleSubmit(e) {
        e.preventDefault();
        const currentPassword = currentPasswordRef.current.value;
        const newPassword = newPasswordRef.current.value;
        const confirmNewPassword = confirmNewPasswordRef.current.value;

        axios.post(`${props.baseURL}/user-cp/change-password`, {
            oldPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmNewPassword
        },{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`,
            }
        }).then( (response) => {
            setAlert(response.data);
            if (response.data.type === "success") {
                currentPasswordRef.current.value = "";
                newPasswordRef.current.value = "";
                confirmNewPasswordRef.current.value = "";
            }
        }).catch( (error) => {
            setAlert({ type: "error", message: error.message });
        })
    }



    return (
        <>
            <PageHeader
                title="Change your password"
                description="Get the power, control, and customization your server through ChatGPT."
                className="mb-3"
                titleTag="h2"
            >
            </PageHeader>
            <Card className="mb-3">
                <Card.Body>
                    {alert.type !== "" && <Alert variant={alert.type === "error" ? "danger" : "success"}>{alert.message}</Alert> }
                    <Form onChange={handleFormChange} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formGroupCurrentPassword">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter current password" ref={currentPasswordRef} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter new password" ref={newPasswordRef} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupConfirmNewPassword">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm new password" ref={confirmNewPasswordRef} />
                        </Form.Group>
                        <Row>
                            <Col className={"text-center"}>
                                <Button variant="primary" type="submit" disabled={disableButton}>
                                    Change Password
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}
export default ChangePassword