import ActionButton from "../common/ActionButton";

const ServerTableActionButton = (props) => {
    let handleClick = () => {
        props.setShowModal({
            show: true,
            serverId: props.serverId,
            serverAlias: props.serverAlias
        })
    }

    return (
        <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" onClick={handleClick} />
    )
}
export default ServerTableActionButton;