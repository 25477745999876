import { useEffect, useRef, useState } from "react";
import PageHeader from "../common/PageHeader";
import FalconComponentCard from "../common/FalconComponentCard";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";

const ChatGPTKey = (props) => {
    const [alert, setAlert] = useState({ type: "", message: "" });
    const [chatGPTKey, setChatGPTKey] = useState("chatGPTKey");
    const chatGPTKeyRef = useRef(null);

    useEffect(() => {
            try {
                axios.get(`${props.baseURL}/user-cp/api-key`, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`,
                    },
                }).then((response) => {
                    if ( response.data.type === "success"){
                        setChatGPTKey(response.data.APIKey);
                    }
                })
            } catch (error) {
                setAlert({ type: "error", message: error.message });
            }

    }, []);

    const handleUpdateKey = async (event) => {
        event.preventDefault()
        try {
            axios.post(
                `${props.baseURL}/user-cp/change-api-key`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`,
                    },
                }
            ).then((response) => {
                if (response.data.type === "success") {
                    setChatGPTKey(response.data.APIKey);
                }
                setAlert(response.data);
            })
        } catch (error) {
            setAlert({ type: "error", message: error.message });
        }
    };

    return (
        <>
            <PageHeader
                title="Integrate ChatGPT Plugin with API Key"
                description="Unleash the power of ChatGPT Plugins with this API key"
                className="mb-3"
            />
            <FalconComponentCard>
                <FalconComponentCard.Body>
                    {alert.type !== "" && (
                        <Alert variant={alert.type === "error" ? "danger" : "success"}>{alert.message}</Alert>
                    )}
                    <Row>
                        <Col className="text-center">
                            <Form>
                                <Form.Group className="mb-3" controlId="formGroupChatGPTKey">
                                    <Form.Label>ChatGPT Key</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="chat-gpt-key"
                                        value={chatGPTKey}
                                        disabled={true}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupChatGPTKey">
                                    <Form.Label>Plugin Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled={true}
                                        value={`${props.baseURL}`}
                                    />
                                </Form.Group>
                                <Button variant="primary" size="md" className="my-3" onClick={handleUpdateKey}>
                                    Update Key
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </FalconComponentCard.Body>
            </FalconComponentCard>
        </>
    );
};

export default ChatGPTKey;