import TextareaAutosize from "react-textarea-autosize";
import React, {useRef, useState} from "react";
import {Button, Form} from "react-bootstrap";
import classNames from "classnames";
import axios from "axios";
import moment from "moment";
const ChatSessionMessageTextArea = ({selectedChat,chatSessions, setChatSessions,baseURL})=>{

    let [message,setMessage] = useState("");
    let currentChatSession = chatSessions.find( (chatSession)=>{
        return chatSession.id === selectedChat
    })

    const handleMessageChange = (e)=>{
        let message = e.target.value;
        if ( message.length > 500){
            return
        }
        setMessage(message)
    }
    const handleSubmit = (e)=>{
        e.preventDefault()
        let updatedChatSessions = chatSessions.map( (chatSession)=>{
            if ( chatSession.id === selectedChat){
                let newLastMessage = {
                    timeStamp: (new Date()),
                    text:message.toString(),
                    side:"human"
                }
                chatSession.messages.push(newLastMessage)
                chatSession.lastMessage = newLastMessage
                chatSession.lastMessageDate = new Date()
            }
            return chatSession
        }).sort((a, b) => {
            return new Date(b.lastMessageDate).getTime() - new Date(a.lastMessageDate).getTime()
        }).map( (chatSession)=>{
            if ( chatSession.id === selectedChat) {
                return {
                    ...chatSession,
                    lastMessageDate: moment(new Date(chatSession.lastMessageDate)).fromNow()
                }
            }
            return chatSession
        })
        setChatSessions(updatedChatSessions)

        axios({
            method:"post",
            url:`${baseURL}/chat-session/send-message`,
            headers:{
                "Authorization":`Bearer ${window.localStorage.getItem("chatssh")}`
            },
            data:{
                chatSessionId:selectedChat,
                message
            }
        }).then( (r)=>{
            setMessage("")
        }).catch( (e)=>{

        })
    }

    return (
        <Form className="chat-editor-area" onSubmit={handleSubmit}>
            <TextareaAutosize
                onChange={ handleMessageChange}
                minRows={1}
                maxRows={6}
                value={message}
                placeholder="Type your message"
                className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
            />
            <small>
                {message.length}/500
            </small>
            <Button
                variant="send"
                size="sm"
                className={classNames('shadow-none', {
                    'text-primary': true
                })}
                type="submit"
                disabled={currentChatSession.lastMessage.side === "human" || currentChatSession.lastMessage.code }
            >
                Send
            </Button>

        </Form>
    )


}
export default ChatSessionMessageTextArea;