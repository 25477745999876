import React, { useState, useRef } from 'react';
import PageHeader from "../common/PageHeader";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {reactBootstrapDocsUrl} from "../../helpers/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FalconComponentCard from "../common/FalconComponentCard";
import axios from 'axios';
import FalconEditor from "../common/FalconEditor";
import IconAlert from "../common/IconAlert";

const AddServerForm = (props) => {
    const [authMethod, setAuthMethod] = useState("PasswordAuth");
    const [disableButton, setDisableButton] = useState(false);
    const [alert,setAlert] = useState({
        type: "",
        message: ""
    })
    const [showTunnelInfo, setShowTunnelInfo] = useState({
        "tunnelToken":""

    });
    const aliasRef = useRef();
    const hostRef = useRef();
    const portRef = useRef();
    const usernameRef = useRef();
    const passwordRef = useRef();
    const pemRef = useRef();

    const handleAuthMethodChange = (event) => {
        setAuthMethod(event.target.value);
    };

    const handleAddServerClick = () => {
        setDisableButton(true);
        let serverData = {}


        let requestURL = `${props.baseURL}/api/add-server-through-ssh`
        if ( authMethod === "TunnelAgent"){
            requestURL = `${props.baseURL}/api/add-server-through-tunnel`
            serverData = {
                alias: aliasRef.current.value,
            }
        }else{
            serverData = {
                alias: aliasRef.current.value,
                host: hostRef.current.value,
                port: portRef.current.value,
                username: usernameRef.current.value,
            }
            if (authMethod === "PasswordAuth") {
                serverData.password = passwordRef.current.value;
            } else if (authMethod === "PemAuth") {
                serverData.privateKey = pemRef.current.value;
            }
        }
        axios.post( requestURL, serverData, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`
            }
        })
            .then((response) => {
                setDisableButton(false);
                setAlert(response.data)
                if ( response.data.tunnelKey){
                    setShowTunnelInfo({
                        "tunnelToken": response.data.tunnelKey
                    })
                }
            })
            .catch((error) => {
                console.log(error)
                setDisableButton(false);
                setAlert(error.response.data)
            })
    };

    const renderAuthMethodForm = () => {
        if (authMethod === "PasswordAuth") {
            return (
                <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="password" ref={passwordRef} />
                </Form.Group>
            );
        } else if (authMethod === "PemAuth") {
            return (
                <Form.Group className="mb-3" controlId="formGroupPem">
                    <Form.Label>PEM</Form.Label>
                    <Form.Control as="textarea" rows={6} placeholder="Example: -----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAKCAQEAtW8WRq3cAohzi5m5c5e5Z8wtvO/YhA9GgMzheVoDyQ2bgAA
...
-----END RSA PRIVATE KEY-----" ref={pemRef} />
                </Form.Group>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <PageHeader
                title="Add Servers with Ease"
                description="Easily add new servers to your network and configure them with our user-friendly form."
                className="mb-3"
            >
            </PageHeader>

            <FalconComponentCard>
                <FalconComponentCard.Body>
                    {alert.type !== "" && <Alert variant={alert.type === "error" ? "danger" : "success"}>{alert.message}</Alert> }
                    {showTunnelInfo.tunnelToken.length > 0 &&
                        <IconAlert variant="info">Please make sure NodeJS is installed on the server. Please run the below command on your server </IconAlert>
                    }
                    {showTunnelInfo.tunnelToken.length > 0 && <FalconEditor
                        code={`npx create-chatsshplug_tunnel --tunnelKey="${showTunnelInfo.tunnelToken}" --tunnelUrl="${props.baseURL}"`}
                        language="js"
                        hidePreview
                    />}
                    <Form>
                        <Form.Group className="mb-3" controlId="formGroupAlias">
                            <Form.Label>Alias</Form.Label>
                            <Form.Control type="text" placeholder="Alias" ref={aliasRef} />
                        </Form.Group>
                        { authMethod !== "TunnelAgent" && <Form.Group className="mb-3" controlId="formGroupHost">
                            <Form.Label>Host</Form.Label>
                            <Form.Control type="text" placeholder="Host" ref={hostRef} />
                        </Form.Group>}
                        { authMethod !== "TunnelAgent" && <Form.Group className="mb-3" controlId="formGroupPort">
                            <Form.Label>Port</Form.Label>
                            <Form.Control type="number" placeholder="Port" ref={portRef} />
                        </Form.Group>}
                        { authMethod !== "TunnelAgent" && <Form.Group className="mb-3" controlId="formGroupUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="Username" ref={usernameRef} />
                        </Form.Group>}
                        <Form.Group className="mb-3" controlId="formGroupAuthMethod">
                            <Form.Label>Authentication Method</Form.Label>
                            <Form.Select value={authMethod} onChange={handleAuthMethodChange}>
                                <option value="PasswordAuth">Password Authentication</option>
                                <option value="PemAuth">PEM Authentication</option>
                                <option value="TunnelAgent">Tunnel Agent</option>
                            </Form.Select>
                        </Form.Group>
                        {renderAuthMethodForm()}
                        <Row>
                            <Col className="text-center">
                                <Button variant="primary" type="button" onClick={handleAddServerClick} disabled={disableButton}>
                                    Add Server
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </FalconComponentCard.Body>

            </FalconComponentCard>
        </>
    )
};

export default AddServerForm;
