import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { CgTrash } from 'react-icons/cg';
import axios from "axios";

const ChatSessionContentHeader = ({ chatSession, setHideSidebar,selectedChat,baseURL }) => {


    let onDeleteSelected = ()=>{
        axios({
            url:`${baseURL}/chat-session/delete-chat`,
            method:"post",
            headers:{
                "Authorization":`Bearer ${window.localStorage.getItem("chatssh")}`
            },
            data:{
                chatSessionId:selectedChat
            }
        }).then ( (r)=>{
            window.location.reload()
        }).catch( (e)=>{
            console.log(e)
        })


    }

    return (
        <div className="chat-content-header">
            <Row className="flex-between-center">
                <Col xs={6} md={8} as={Flex} alignItems="center">
                    <div
                        className="pe-3 text-700 d-md-none contacts-list-show cursor-pointer"
                        onClick={() => setHideSidebar(true)}
                    >
                        <FontAwesomeIcon icon="chevron-left" />
                    </div>
                    <div className="min-w-0">
                        <h5 className="mb-0 text-truncate fs-0">{chatSession.serverAlias}-{chatSession.Title}</h5>
                    </div>
                </Col>
                <Col xs="auto">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Delete chat</Tooltip>}
                    >
                        <Button variant="falcon-primary" className="me-2" size="sm" onClick={onDeleteSelected}>
                           <CgTrash size={25}/>
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </div>
    );
};

export default ChatSessionContentHeader;
