import ActionButton from "../common/ActionButton";
import {Button, Col, Row, Table} from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";

const CommandDisplay = props => {
    let [messages, setMessages] = useState([]);
    useEffect(() => {
        if (props.currentServer) {
            let messageList = props.currentServer[props.type];
            console.log(props.currentServer)
            console.log(messageList);
            if (messageList && messageList.length > 0) {
                setMessages(messageList);
            }
        }
    }, [props.currentServer]);

    const clearLogs = async () => {
        try {
            const response = await axios.post(  `${props.baseURL}/api/logs`, {
                alias: props.currentServer.alias,
                logsType: props.type,
                action: "clear"
            }, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`
                }
            });
            if ( response.data.type === "success") {
                setMessages([]);
                props.setCurrentServer({
                    ...props.currentServer,
                    messageFromServer:[],
                    messageToServer:[]
                })
                props.setAlert(response.data)
            }
        } catch (error) {
            props.setAlert(error.response.data)
        }
    };

    return (
        <>
            <Row>
                <Col className={"text-center"}>
                    <Button
                        variant="danger"
                        onClick={clearLogs}

                    >
                        Clear Logs
                    </Button>
                </Col>
            </Row>
            <Table responsive>
                <thead>
                <tr>
                    <th>#</th>
                    <th scope="col">Timestamp</th>
                    <th scope="col">Message</th>
                </tr>
                </thead>
                <tbody>
                {messages.length > 0 &&
                    messages.map((message, index) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                
                                <td>{message.timeStamp}</td>
                                <td>{message.data}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>


        </>
    );
};

export default CommandDisplay;
