import {Button, Form} from "react-bootstrap";
import classNames from "classnames";
import React, {useEffect, useState} from "react";
import axios from "axios";
import IconAlert from "../common/IconAlert";
import qs from "qs";

const TwoFactorAuthFormLayout = (props) => {

    let[token, setToken] = useState("")
    let [buttonDisabled, setButtonDisabled] = useState(false)
    let [alert, setAlert] = useState({
        type:"",
        message:""
    })
    const handleTokenChange = (e)=>{
        setToken(e.target.value)
    }

    const handleClick = (e)=>{
        e.preventDefault()
        setButtonDisabled(true)
        axios({
            method:"POST",
            url: props.baseURL + "/2fa/verify-otp",
            headers:{
                Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`
            },
            data:{
                token:token
            }
        }).then( (r)=>{
            setAlert({
                type:"success",
                message:"Successfully entered OTP, please wait while we redirect you"
            })
            let queryParams = window.location.search
            if ( queryParams.length > 0){
                queryParams = queryParams.substring(1,queryParams.length)
                let queryToJSON = qs.parse(queryParams)
                if ( queryToJSON.redirect_uri){
                    window.location.href = (`${queryToJSON.redirect_uri}?code=${window.localStorage.getItem("chatssh")}&state=${queryToJSON.state}`)
                }
            }
            setTimeout( ()=>{
                window.location.href = "/app/dashboard"
            }, 3000)
        }).catch( (e)=>{
            setButtonDisabled(false)
            setAlert(e.response.data)
            console.log(e.response.data)
        })
    }

    return (
        <Form
            className={classNames('mt-3', { 'text-left': true })}
            onSubmit={handleClick}
        >
            <Form.Group className="mb-3">
                {alert.message.length > 0 && <IconAlert  variant={alert.type === "error" ? "danger" : "success"}>{alert.message}</IconAlert> }
                <Form.Label>Please enter code from Authenticator</Form.Label>
                <Form.Control
                    placeholder={"123456"}
                    value={token}
                    onChange={handleTokenChange}
                />
            </Form.Group>
            <Button
                type="submit"
                className="w-100"
                disabled={buttonDisabled}
            >
                Proceed
            </Button>
        </Form>
    )
}
export default TwoFactorAuthFormLayout;