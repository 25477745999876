import PageHeader from "../common/PageHeader";
import {Alert, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import AllUsersTableBody from "./AllUsersTableBody";
const AllUsers = (props)=>{


    return (
        <>
            <PageHeader
                title="View all users"
                description="View all users."
                className="mb-3"
                titleTag="h2"
            >
            </PageHeader>
            <Card className="mb-3">
                <Card.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Username</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <AllUsersTableBody baseURL={props.baseURL}/>
                    </Table>

                </Card.Body>
            </Card>
        </>
    )
}
export default AllUsers;