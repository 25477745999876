import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import {FaServer} from "react-icons/fa";
import {BiSupport} from "react-icons/bi";
import {MdSecurity} from "react-icons/md";

const CardService = ({ media, title, description, children }) => (


  <Card className="card-span h-100">
    <div className="card-span-img">
        {media.icon === "faSecure" && <MdSecurity size={50} />}
        {media.icon === "faSupport" && <BiSupport size={50} />}
        {media.icon === "faServer" && <FaServer size={50} />}

    </div>
    <Card.Body className="pt-6 pb-4">
      <h5 className="mb-2">{title}</h5>
      {description && <p>{description}</p>}
    </Card.Body>
  </Card>
);

CardService.propTypes = {
  media: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    color: PropTypes.string.isRequired,
    className: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node
};

export default CardService;
