import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Button, Form, Spinner} from 'react-bootstrap';
import axios from "axios";
import IconAlert from "../common/IconAlert";
import qs from "qs";
import {useEffect} from "react";

const ForgetPasswordForm = (props) => {
  // State
  const [email, setEmail] = useState('');
  const[disableButton, setDisableButton] = useState(false)

  const [alert,setAlert] = useState({
        type:"",
        message:""
  })
    const [code,setCode] = useState("")
    const [isLoading,setIsLoading] = useState(true)
    const [showNewPassword,setShowNewPassword] = useState(false)
    const [newPassword,setNewPassword] = useState("")
    const [confirmPassword,setConfirmPassword] = useState("")

    const [buttonText,setButtonText] = useState("Send Reset Link")
    useEffect(()=>{
        let queryParams = (window.location.search)
        if ( queryParams.length > 0 ){
            queryParams = queryParams.substring(1,queryParams.length)
            let query = qs.parse(queryParams)
            let email = query.email
            let code = query.code
            if ( email && code ){
                axios({
                    method:"post",
                    url: props.baseURL + "/user/reset-password",
                    data:{
                        email:email,
                        code:code,
                        isResetPasswordRequest:false
                    }
                }).then( (r)=>{
                    setIsLoading(false)
                    setShowNewPassword(true)
                    setButtonText("Reset Password")
                    setEmail(email)
                    setCode(code)
                    setAlert(r.data)
                }).catch( (e)=>{
                    setIsLoading(false)
                    setAlert({
                        type:"error",
                        message:e.response.data.message
                    })
                })
            }else{
                setIsLoading(false)
            }
        }else{
            setIsLoading(false)
        }
    },[])
    const handleSubmit =  (e) => {
      e.preventDefault()
      setDisableButton(true)
        if ( !showNewPassword ){
            axios({
                method: 'post',
                url: props.baseURL + '/user/forgot-password',
                data: {
                    email: email
                }
            }).then(( r)=>{
                setAlert({
                    type:"success",
                    message:r.data.message
                })
            }).catch((e)=>{
                if ( e.response ){
                    if ( e.response.data ){
                        setAlert({
                            type:"error",
                            message:e.response.data.message
                        })
                    }
                }
                setDisableButton(false)
            })
        }else{
            axios({
                method:"post",
                url: props.baseURL + "/user/reset-password",
                data:{
                    email:email,
                    code:code,
                    isResetPasswordRequest:true,
                    newPassword:newPassword,
                    confirmNewPassword:confirmPassword
                }
            }).then( (r)=>{
                setAlert({
                    type:"success",
                    message:r.data.message
                })
                setTimeout(()=>{
                    window.location.href = "/auth/login"
                },3000)
            }).catch( (e)=>{
                if ( e.response ){
                    if ( e.response.data ){
                        setAlert({
                            type:"error",
                            message:e.response.data.message
                        })


                    }
                }
                setDisableButton(false)
            })
        }


    }

  return (
      <>

          {isLoading && <Spinner className="position-absolute start-50" animation="grow" /> }

          {!isLoading && <>

    <Form className="mt-4" onSubmit={handleSubmit}>
        {alert.message.length > 0 && <IconAlert variant={alert.type === "error" ? "danger" :"success"}>{alert.message}</IconAlert>}

        <Form.Group className="mb-3">
              <Form.Control
              placeholder={'Email address'}
              value={email}
              name="email"
              onChange={({ target }) => setEmail(target.value)}
              type="email"
              disabled={showNewPassword}
            />
      </Form.Group>
        {showNewPassword && <>
            <Form.Group className="mb-3">
                <Form.Control
                    placeholder={'Code'}
                    value={code}
                    name="code"
                    type="text"
                    disabled={true}
                />
            </Form.Group>
        <Form.Group className="mb-3">
            <Form.Control
                placeholder={'Password'}
                value={newPassword}
                name="password"
                onChange={({ target }) => setNewPassword(target.value)}
                type="password"
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Control
                placeholder={'Confirm new password'}
                value={confirmPassword}
                name="confirmPassword"
                onChange={({ target }) => setConfirmPassword(target.value)}
                type="password"
            />
        </Form.Group>
        </>}

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={disableButton}>
            {buttonText}
        </Button>
      </Form.Group>

    </Form>
          </>}
      </>

  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
