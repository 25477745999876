export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Default',
          to: '/',
          exact: true,
          active: true
        },
        {
          name: 'Analytics',
          to: '/dashboard/analytics',
          active: true
        },
        {
          name: 'CRM',
          to: '/dashboard/crm',
          active: true
        },
        {
          name: 'E Commerce',
          to: '/dashboard/e-commerce',
          active: true
        },
        {
          name: 'LMS',
          to: '/dashboard/lms',
          active: true,
          badge: {
            type: 'success',
            text: 'New'
          }
        },
        {
          name: 'Management',
          to: '/dashboard/project-management',
          active: true
        },
        {
          name: 'SaaS',
          to: '/dashboard/saas',
          active: true
        },
        {
          name: 'Support desk',
          to: '/dashboard/support-desk',
          active: true,
          badge: {
            type: 'success',
            text: 'New'
          }
        }
      ]
    }
  ]
};
export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Dashboard',
      icon: 'calendar-alt',
      to: '/app/dashboard',
      active: true
    },
    {
      name: 'Add Server',
      active: true,
      icon: 'file-alt',
      to: '/app/add-server'
    },
    {
      name:"PlugBot",
      active:true,
      icon:"file-alt",
      to:"/app/start-chat-session"
    },
    {
      name: 'ChatGPT Key',
      active: true,
      icon: 'file-alt',
      to: '/app/chatgpt-key'
    },
  ]
};

export const pagesRoutes = {
  label: 'Payment',
  children: [
    {
      name: 'Purchase',
      icon: 'flag',
      to: '/app/purchase',
      active: true
    },
    {
      name: 'Payment History',
      icon: 'globe',
      to: '/app/purchase-history',
      active: true
    },

  ]
};

export const modulesRoutes = {
  label: 'User settings',
  children: [
    {
      name: 'Change Password',
      icon: 'table',
      active: true,
      to: '/app/change-password'
    },
    {
      name: '2FA',
      icon: 'lock',
      active: true,
      to: '/app/2fa'
    },

  ]
};

export const documentationRoutes = {
  label: 'Documentation',
  children: [
    {
      name: 'API Docs',
      icon: 'rocket',
      to: '/api-docs',
      active: true
    }
  ]
};

export default [
  appRoutes,
  pagesRoutes,
  modulesRoutes,
  documentationRoutes
];
