import {Accordion, Col, Row, Form, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";
import FalconEditor from "../common/FalconEditor";

const TunnelInfoTab = ({baseURL, setAlert, currentServer})=>{
    let [tunnelInfo,setTunnelInfo] = useState({
        tunnelToken:"",
    })

    useEffect( ()=>{
        if ( currentServer){
            console.log(currentServer)
            axios({
                method:"post",
                url:`${baseURL}/dashboard/get-tunnel-info`,
                headers:{
                    "Authorization": "Bearer " + window.localStorage.getItem("chatssh")
                },
                data:{
                    tunnelId: currentServer.id
                }
            }).then ( (resp)=>{
                setTunnelInfo({
                    tunnelToken: resp.data.tunnelToken
                }).catch( (err)=>{
                    setAlert({
                        type:"danger",
                        message: err.response.data.message
                    })
                })

            })
        }

    },[])




    return (
        <>

            {tunnelInfo.tunnelToken.length === 0 && <Spinner className="position-absolute start-50" animation="grow" />}

            {tunnelInfo.tunnelToken.length > 0 && <>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Tunnel Token</Form.Label>
                            <Form.Control type="text" value={tunnelInfo.tunnelToken} placeholder="3a26043a-ad8a-4f9f-9deb-bb1ad71ed568" disabled={true}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>NodeJS must be installed on computer</Accordion.Header>
                                <Accordion.Body>
                                    <p><a href={"https://nodejs.org/en/download"}>Please visit the official Node.js website to download and install Node.js</a> </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Run the following command</Accordion.Header>
                                <Accordion.Body>
                                    <FalconEditor
                                        code={`npx create-chatsshplug_tunnel --tunnelKey="${tunnelInfo.tunnelToken}" --tunnelUrl="${baseURL}"`}
                                        language="js"
                                        hidePreview
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </>
            }
        </>
    )
}

export default TunnelInfoTab;