import ThreadInfo from "../../../app/chat/content/ThreadInfo";
import SimpleBarReact from "simplebar-react";
import ChatContentBodyIntro from "../../../app/chat/content/ChatContentBodyIntro";
import Message from "../../../app/chat/content/Message";
import React, {useEffect, useRef} from "react";
import ChatSessionMessage from "./ChatSessionMessage";
import moment from "moment";
const ChatSessionContentBody = ({chatSession,scrollingRef,selectedChat})=>{

    useEffect(()=>{
        setTimeout(()=>{
            scrollingRef.current.scrollIntoView({behavior: "smooth" })

        },1000)

    },[chatSession])

    return (
        <div className="chat-content-body" style={{ display: 'inherit' }}>
            <SimpleBarReact style={{ height: '100%' }}>
                <div className="chat-content-scroll-area">
                    {chatSession.messages.map((message, index) => (
                        <div key={index}>
                                <div className="text-center fs--2 text-500">{`${moment(message.timeStamp).fromNow()}`}</div>
                            <ChatSessionMessage
                                message={message}
                                isLeft={message.side === "AI"}
                            />
                            {
                                index === chatSession.messages.length - 1
                                && (
                                    chatSession.messages[index].side==="human"
                                || chatSession.messages[index].code

                                )
                                && (
                                    <>
                                        <ChatSessionMessage message={{
                                            side:"AI",
                                            loading:true,
                                        }} isLeft={true}/>

                                    </>
                                )
                            }
                            <span ref={scrollingRef}></span>
                        </div>
                    ))}
                </div>
            </SimpleBarReact>
        </div>
    )
}
export default ChatSessionContentBody;