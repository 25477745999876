import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import qs from 'qs'
const LoginForm = ({ hasLabel, layout,baseURL }) => {
    // State
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [alert,setAlert] = useState(()=>{
        return{
            "variant":"",
            "message":""
        }
    })
    const navigate = useNavigate();

    // Handler
    const handleSubmit = e => {
        e.preventDefault();
        setIsLoading(true);
        axios.post( `${baseURL}/user/login`, formData)
            .then(response => {
                if (response.data.type === 'success') {
                    window.localStorage.setItem('chatssh', response.data.token);
                    let searchParams = window.location.search
                    let token = response.data.token
                    if ( searchParams && searchParams.length > 0){
                        searchParams = searchParams.substring(1,searchParams.length)
                        let queryToJSON = qs.parse(searchParams)
                        if ( queryToJSON.redirect_uri){
                            axios({
                                method: 'post',
                                url: `${baseURL}/dashboard/`,
                                headers: {
                                    "Authorization": `Bearer ${response.data.token}`
                                }
                            }).then ( (response)=>{
                                if ( response.data.type === "success"){
                                    window.location.href = (`${queryToJSON.redirect_uri}?code=${token}&state=${queryToJSON.state}`)
                                }
                            }).catch( (e)=>{
                                if ( e.response.data.type === "error"){
                                   if ( e.response.data.message === "OTP_REQUIRED"){
                                        navigate(`/auth/2fa${window.location.search}`);
                                   }
                                }
                            })
                        }else{
                            navigate('/app/dashboard');
                        }
                    }else{
                        navigate('/app/dashboard');
                    }

                } else {
                    setAlert(()=>{
                        return {
                            "variant":"danger",
                            "message":response.data.message
                        }
                    })
                }
            })
            .catch(error => {
                setAlert(()=>{
                    return {
                        "variant":"danger",
                        "message":error.response.data.message
                    }
                })
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            {alert.message.length >0 && <Alert variant={alert.variant}>{alert.message}</Alert>}
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Email address</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Email address' : ''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Password</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Password' : ''}
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                />
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col className={"text-end"}>
                        <Link to="/auth/forgot-password">Forgot password?</Link>
                    </Col>
                </Row>

            </Form.Group>
            <Form.Group>
                <Button
                    type="submit"
                    color="primary"
                    className="mt-3 w-100"
                    disabled={!formData.email || !formData.password || isLoading}
                >
                    {isLoading ? 'Logging in...' : 'Log in'}
                </Button>
            </Form.Group>


            <Divider className="mt-4">or</Divider>

            <Button
                variant="danger"
                className="mt-3 w-100"
                onClick={() => {
                    navigate(`/auth/register${window.location.search}`);
                }}
            >
                Register
            </Button>
        </Form>
    );
};

LoginForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default LoginForm;