import {Card, Col, Row, Spinner, Tab} from "react-bootstrap";
import Flex from "../../common/Flex";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import ChatSessionSidebar from "./ChatSessionSideBar/ChatSessionSidebar";
import ChatSessionContent from "./ChatSessionContent/ChatSessionContent";
import CreateConversationModal from "./CreateConversationModal";
import moment from "moment";
import SaasRevenue from "../../dashboards/saas/SaasRevenue";
import {IoServer} from "react-icons/io5";
import {FaServer} from "react-icons/fa";
import {AiFillMessage} from "react-icons/ai";
const ChatSession = (props)=>{

    const [hideSidebar, setHideSidebar] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [loading,setLoading] = useState(true);
    const [chatSessions, setChatSessions] = useState([]);
    const [selectedChat,setSelectedChat] = useState( "")
    const [usedPlugBotMessages,setUsedPlugBotMessages] = useState(0)
    const [availablePlugBotMessages,setAvailablePlugBotMessages] = useState(0)

    const scrollingRef = useRef()

    const getChatSessions = ()=>{
        return axios({
            method:"get",
            headers:{
                "Authorization":`Bearer ${window.localStorage.getItem("chatssh")}`
            },
            url:`${props.baseURL}/chat-session`
        }).then( (r)=>{
            setUsedPlugBotMessages(r.data.UsedPlugBotMessages)
            setAvailablePlugBotMessages(r.data.PlugBotMessagesAvailable)

            return r.data.chatSessions.sort((a, b) => {
                return new Date(b.lastMessageDate).getTime() - new Date(a.lastMessageDate).getTime()
            }).map( (chatSession)=>{
                return {
                    ...chatSession,
                    lastMessageDate: moment(new Date(chatSession.lastMessageDate)).fromNow()
                }
            })
        }).catch( (e)=>{
            console.log(e.response.data)
        })
    }
    useEffect( ()=>{
        setLoading(false)
        getChatSessions().then( (chatSessions)=>{
            if ( chatSessions.length === 0){
                setModalShow(true)
                return
            }
            setSelectedChat(chatSessions[0].id)
            setChatSessions(chatSessions)
        })
        props.socket.on("new-chat-message",()=>{
            console.log("Event recieved")
            getChatSessions().then( (chatSessions)=>{
                setSelectedChat(chatSessions[0].id)
                setChatSessions(chatSessions)
            })
        })



    },[])
    useEffect( ()=>{
        if ( chatSessions.length > 0 ){
            setSelectedChat(chatSessions[0].id)
        }

    },[chatSessions])

    return (
        <>


                <CreateConversationModal chatSessions={chatSessions} modalShow={modalShow} setModalShow={setModalShow} baseURL={props.baseURL} />

                {
                    loading && <Spinner className="position-absolute start-50" animation="grow" />
                }
                {
                    !loading &&<>
                        <Row className="g-3" style={{
                            paddingBottom:"10px"
                        }}>
                            <Col>
                                <Row className="g-3">
                                    <Col md={4} xxl={12}>
                                        <SaasRevenue icon={<AiFillMessage/>} heading={"Available PlugBot Messages"} count={availablePlugBotMessages}/>
                                    </Col>
                                    <Col md={4} xxl={12}>
                                        <SaasRevenue icon={<IoServer/>} heading={"Past PlugBot Messages"} count={usedPlugBotMessages} />
                                    </Col>
                                    <Col md={4} xxl={12}>
                                        <SaasRevenue icon={<FaServer/>} heading={"Chat Sessions"} count={chatSessions.length}/>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        { chatSessions.length > 0 &&
                        <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey={chatSessions[0].id}
                        >
                            <Card className="card-chat overflow-hidden">

                                <Card.Body as={Flex} className="p-0 h-100">
                                    <ChatSessionSidebar
                                        hideSidebar={hideSidebar}
                                        chatSessions={chatSessions}
                                        setModalShow={setModalShow}
                                        setSelectedChat={setSelectedChat}
                                        selectedChat={selectedChat}
                                    />
                                    <ChatSessionContent
                                        setHideSidebar={setHideSidebar}
                                        chatSessions={chatSessions}
                                        selectedChat={selectedChat}
                                        setChatSessions={setChatSessions}
                                        baseURL={props.baseURL}
                                        scrollingRef={scrollingRef}
                                    />
                                </Card.Body>
                            </Card>
                        </Tab.Container>
                        }

                    </>
                }

        </>
    )
}
export default ChatSession;