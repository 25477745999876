import {Card, Col, Row} from "react-bootstrap";
import LmsStatItem from "../dashboards/lms/lms-stats/LmsStatItem";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";
import {AiFillLock,AiFillUnlock} from "react-icons/ai";

const TwoFactorStatusLock = ()=>{
    return (
                <Row className="g-0" style={{
                    paddingTop:"10px",
                }}>
                    <Col className={"col d-flex justify-content-center align-items-center"}>
                        <div className={`icon-circle icon-circle-red`} style={{
                            color:"green"
                        }}>
                            <AiFillLock size={"20"} />
                        </div>
                    </Col>
                </Row>

    )

}
export default TwoFactorStatusLock;