import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';
import {useEffect} from "react";
import axios from "axios";

const Login = (props) => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Don't have an account?
          <br />
          <Link
            className="text-white text-decoration-underline"
            to="/authentication/card/register"
          >
            Get started!
          </Link>
        </p>
      }
    >
      <h3>Account Login</h3>
      <LoginForm layout="card" hasLabel baseURL={props.baseURL}/>
    </AuthCardLayout>
  );
};

export default Login;
