import React from 'react';
import {Button, Col} from 'react-bootstrap';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import axios from 'axios';

const PurchaseCard = (props) => {

    let description = props.pricing.Title.split("-");
    let Price = props.pricing.Price;
    let title = description[0];
    let subTitle = description[1];
    let buttonText = "Checkout with PayPal";
    let isFeatured = props.isFeatured;

    let plugBotMessages = 0
    if ( props.pricing.Title.includes("Basic")){
        plugBotMessages = 100
    }
    if ( props.pricing.Title.includes("Dev")){
        plugBotMessages = 200
    }
    if ( props.pricing.Title.includes("Inter")){
        plugBotMessages = 300
    }
    let features = [
        `Unlimited ChatGPT Plugin Access`,
        `Customer Support`,
        `Output logs`,
        `Secure`,
        `API Access`,
        `${plugBotMessages} PlugBot Messages`

    ];
    let buttonClicked = async (e) => {
        e.preventDefault();
        const response = await axios.post(`${props.baseURL}/checkout/create-checkout-session`,{
            packageId: props.pricing.id
        },{
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("chatssh")}`
            }
        });
        window.location.href = response.data.paypalLink;
    };

    return (
        <Col
            lg={4}
            className={classNames('border-top border-bottom', {
                'dark__bg-1000 px-4 px-lg-0': isFeatured
            })}
            style={{ backgroundColor: isFeatured && 'rgba(115, 255, 236, 0.18)' }}
        >
            <div className="h100">
                <div className="text-center p-4">
                    <h3 className="fw-normal my-0">{title}</h3>
                    <p className="mt-3">{subTitle}</p>
                    <h2 className="fw-medium my-4">
                        <sup className="fw-normal fs-2 me-1">$</sup>
                        {Price}
                        <small className="fs--1 text-700">/ month</small>
                    </h2>
                    <Button
                        onClick={buttonClicked}
                        variant={isFeatured ? 'primary' : 'outline-primary'}
                    >
                        {buttonText}
                    </Button>
                </div>
                <hr className="border-bottom-0 m-0" />
                <div className="text-start px-sm-4 py-4">
                    <ul className="list-unstyled mt-3">
                        {features.map(feature => (
                            <li className="py-1">
                                <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                                {feature}{' '}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Col>
    );
};

export default PurchaseCard;
