import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import MainLayout from "./layouts/MainLayout";
import Landing from "./components/pages/landing/Landing";
import Login from "./components/authentication/card/Login";
import Registration from "./components/authentication/card/Registration";
import {io} from "socket.io-client";
import Logout from "./components/authentication/card/Logout";
import TwoFactorAuthForm from "./components/ChatSSHPlugComponents/TwoFactorAuthForm";
import ForgetPassword from "./components/authentication/card/ForgetPassword";
const ApiDocs = ()=>{

  window.location.href = "/api-docs"

}
const App = () => {
    let baseURL = "https://" + window.location.host
    if ( baseURL.includes("localhost")){
      baseURL = "http://localhost:8087"
    }
    let socket = io(baseURL,{
        extraHeaders:{
            token: window.localStorage.getItem("chatssh")
        }
    })

  return (
    <Router basename={process.env.PUBLIC_URL}>
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path='/auth/login' element={<Login baseURL={baseURL}/>} />
            <Route path='/auth/register' element={<Registration baseURL={baseURL}/>}/>
            <Route path='/auth/forgot-password' element={<ForgetPassword baseURL={baseURL}/>}/>
            <Route path='/auth/2fa' element={<TwoFactorAuthForm baseURL={baseURL}/>}/>
            <Route path={"/api-docs"} element={<ApiDocs />}/>
            <Route path="/app/*" element={<MainLayout baseURL={baseURL} socket={socket}/>}/>

            <Route path="*" element={<Landing />} />
        </Routes>
    </Router>
  );
};


export default App;
