import {Alert, Button, Col, Form, Row, Table} from "react-bootstrap";
import PageHeader from "../common/PageHeader";
import FalconComponentCard from "../common/FalconComponentCard";
import {useEffect, useState} from "react";
import axios from "axios";
const PurchaseHistory = (props) => {

    let [purchased,setPurchased] = useState([])
    useEffect(() => {
        axios.post(`${props.baseURL}/checkout/purchase-history`,{},{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`,
            }
        })
            .then(response => {
                setPurchased(response.data.purchased);
            })
            .catch(error => {
                console.error(error);
            });
    },[])

    return (
        <>
        <PageHeader
            title="Integrate ChatGPT Plugin with API Key"
            description="Unleash the power of ChatGPT Plugins with this API key"
            className="mb-3"
        />
    <FalconComponentCard>
        <FalconComponentCard.Body>
            <Table responsive >
                <thead>
                <tr>
                    <th scope="col">Package</th>
                    <th scope="col">Price</th>
                    <th scope="col">PayPal Email</th>
                    <th scope="col">Purchase Date</th>
                </tr>
                </thead>
                <tbody>
                {purchased.length > 0 && purchased.map((purchase) => {
                    return (
                        <tr>
                            <td>{purchase.packageName}</td>
                            <td>${purchase.packagePrice}.00</td>
                            <td>{purchase.payerPaypalEmail}</td>
                            <td>{purchase.purchaseDate}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </FalconComponentCard.Body>
    </FalconComponentCard>
</>
    )



}
export default PurchaseHistory;