import {Tab} from "react-bootstrap";
import MessageTextArea from "../../../app/chat/content/MessageTextArea";
import React from "react";
import ChatSessionContentHeader from "./ChatSessionContentHeader";
import ChatSessionContentBody from "./ChatSessionContentBody";
import ChatSessionMessageTextArea from "./ChatSessionMessageTextArea";

const ChatSessionContent = ({chatSessions,setHideSidebar,setChatSessions, selectedChat,baseURL,scrollingRef })=>{

    return (
        <Tab.Content className="card-chat-content">
            {chatSessions.map((chatSession, index) => (
                <Tab.Pane key={chatSession.id} eventKey={chatSession.id} className="card-chat-pane">
                    <ChatSessionContentHeader chatSession={chatSession} setHideSidebar={setHideSidebar} selectedChat={selectedChat} baseURL={baseURL}/>
                    <ChatSessionContentBody chatSession={chatSession} scrollingRef={scrollingRef} selectedChat={selectedChat}/>
                </Tab.Pane>
            ))}
            <ChatSessionMessageTextArea selectedChat={selectedChat} setChatSessions={setChatSessions} chatSessions={chatSessions} baseURL={baseURL} />
        </Tab.Content>
    )

}
export default ChatSessionContent;