import React from 'react';
import { Card } from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DepositeStatus = (props) => {

  return (
    <Card className="bg-light my-3">
      <Card.Body className="p-3">
        <p className="fs--1 mb-0">
          <Link to={props.status.activePlan ? "#" : "/app/purchase"}>
            <FontAwesomeIcon
              icon="exchange-alt"
              className="me-2"
              transform="rotate-90"
            />
            {props.status.activePlan ? "You have purchased our " : ""}
            <strong >{props.status.activePlan ? props.status.Title : "Please purchase a plan to use our ChatGPT SSH Plugin"}</strong>. {props.status.activePlan ? `Your plan expires on ${props.status.expiresIn}` : ""}
          </Link>{' '}
        </p>
      </Card.Body>
    </Card>
  );
};

export default DepositeStatus;
