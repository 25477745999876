import {Button, Form} from "react-bootstrap";
import React, {useRef, useState} from "react";
import axios from "axios";

const StartConversationForm = ({servers,baseURL,setModalAlert})=>{


    const [selectedServer,setSelectedServer] = useState(servers[0].alias)
    const titleRef = useRef("")
    const [buttonDisabled,setButtonDisabled] = useState(false)
    const handleServerChange = (e)=>{
        setSelectedServer(e.target.value)
    }
    const handleClick = ()=>{
        setButtonDisabled(true)
        axios({
            method:"POST",
            headers:{
                "Authorization":"Bearer " + window.localStorage.getItem("chatssh")
            },
            data:{
                title:titleRef.current.value,
                alias:selectedServer
            },
            url:`${baseURL}/chat-session/create-chat-session`
        }).then( (r)=>{
            setModalAlert({
                type:"success",
                message:"Conversation created successfully"
            })
            setTimeout( ()=>{
                window.location.reload()
            },2000)

        }).catch((e)=>{
            setButtonDisabled(false)
            console.log(e)
            setModalAlert({
                type:"danger",
                message:e.response.data.message
            })

        })
    }
    return (
        <>
            <Form.Group className="mb-3" controlId="formGroupAlias">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="RAID Setup"  ref={titleRef}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupAuthMethod">
                <Form.Label>Select Server</Form.Label>
                <Form.Select value={selectedServer} onChange={handleServerChange}>
                    {
                        servers.map( (server)=> {
                            return (
                                <><option value={server.alias}>{server.alias}</option> </>
                            )
                        })
                    }
                </Form.Select>
            </Form.Group>
            <Button variant="outline-primary" id="button-addon2" className={"text-center"} onClick={handleClick} disabled={buttonDisabled}>
                Create Chat
            </Button>


        </>

    )

}
export default StartConversationForm