import React, {useEffect} from 'react';
import LogoutContent from 'components/authentication/LogoutContent';

import AuthCardLayout from 'layouts/AuthCardLayout';
import axios from "axios";

const Logout = (props) => {

    useEffect(() => {
        axios.post(`${props.baseURL}/usercp/logout`, {},{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`
            }
        }).then(function (response) {
            if (response.data.type === 'success') {
                window.localStorage.removeItem("chatssh");
                window.location.href = window.location.origin + '/';
            }
        }).catch(function (error) {
            window.localStorage.removeItem("chatssh");
            window.location.href = window.location.origin + '/';
        });
    },[]);

    return (<></>)
};

export default Logout;
