import {useRef, useState} from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import axios from "axios";

const ServerInfo = (props) => {
    const aliasRef = useRef(props.currentServer?.alias ?? '');
    const statusRef = useRef(props.currentServer?.status ?? '');
    const hostRef = useRef(props.currentServer?.host ?? '');
    const portRef = useRef(props.currentServer?.port ?? '');
    const usernameRef = useRef(props.currentServer?.username ?? '');
    const chatGPTAccessibleRef = useRef(props.currentServer?.chatGPTAccessible ?? false);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const handleUpdateClick = async (e) => {
        e.preventDefault();
        const aliasValue = aliasRef.current.value;
        const hostValue = hostRef.current.value;
        const portValue = portRef.current.value;
        const usernameValue = usernameRef.current.value;

        try {
            const response = await axios.post(
                `${props.baseURL}/api/editserver`,
                {
                    oldAlias: props.currentServer.alias,
                    alias: aliasValue,
                    host: hostValue,
                    port: portValue,
                    username: usernameValue,
                },
                {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('chatssh')}`,
                    },
                }
            ).then( (resp)=>{
                props.setAlert(resp.data)

            }).catch( (err)=>{
                props.setAlert(err.response.data)
            })
        } catch (error) {
            console.error('Error updating server:', error);
        }
    };

    const handleDeleteClick = (e) => {
        e.preventDefault()
        axios.delete(props.baseURL + '/api/deleteserver', {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('chatssh')}`
            },
            params: {
                alias:  props.currentServer.alias
            }
        })
            .then(function(response) {
                props.setAlert(response.data)
                setDeleteClicked(true)
            })
            .catch(function(error) {
                props.setAlert(error.response.data)
            });
    };

    return (

        <>

        {!deleteClicked &&
    <Form>
            <Form.Group className="mb-3" controlId="formGroupAlias">
                <Form.Label>Alias</Form.Label>
                <Form.Control type="text" placeholder="Alias" ref={aliasRef} defaultValue={props.currentServer?.alias} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupHost">
                <Form.Label>Host</Form.Label>
                <Form.Control type="text" placeholder="Host" ref={hostRef} defaultValue={props.currentServer?.host} disabled={!props.currentServer.port}/>
            </Form.Group>

        {props.currentServer.port &&
            <Form.Group className="mb-3" controlId="formGroupPort">
                <Form.Label>Port</Form.Label>
                <Form.Control type="number" placeholder="Port" ref={portRef} defaultValue={props.currentServer?.port} />
            </Form.Group>
        }
        {!props.currentServer.port &&
            <Form.Group className="mb-3" controlId="formGroupHost">
                <Form.Label>OS</Form.Label>
                <Form.Control type="text" placeholder="OS"  defaultValue={props.currentServer?.os} disabled={!props.currentServer.port}/>
            </Form.Group>

        }
            <Form.Group className="mb-3" controlId="formGroupUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" placeholder="Username" ref={usernameRef} defaultValue={props.currentServer?.username} disabled={!props.currentServer.port} />
            </Form.Group>
            <Row>
                <Col className="text-start">
                    <Button variant="primary" type="button" onClick={handleUpdateClick}>
                        Update
                    </Button>
                </Col>
                <Col className="text-end">
                    <Button variant="danger" type="button" onClick={handleDeleteClick}>
                        Delete
                    </Button>
                </Col>
            </Row>
        </Form>
        }
        </>

    );
};

export default ServerInfo;
