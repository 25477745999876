import classNames from "classnames";
import SimpleBarReact from "simplebar-react";
import {Col, Nav, Row} from "react-bootstrap";
import ChatThread from "../../../app/chat/sidebar/ChatThread";
import ChatContactsSearch from "../../../app/chat/sidebar/ChatContactSearch";
import React from "react";
import ChatSessionThread from "./ChatSessionThread";
import IconButton from "../../../common/IconButton";
import {v4 as uuidv4} from "uuid";
const ChatSessionSidebar = ({hideSidebar,chatSessions,setModalShow,setSelectedChat})=>{
    return (
        <div className={classNames('chat-sidebar', { 'start-0': hideSidebar })}>
            <div className="contacts-list">
                <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>

                    <Nav className="border-0">
                        {chatSessions.length > 0 && chatSessions.map((chatSession, index) => (
                            <ChatSessionThread
                                key={uuidv4()}
                                chatSessionId={chatSession.id}
                                setSelectedChat={setSelectedChat}
                                index={chatSession.id}
                                lastMessageTitle={`${chatSession.Title}` }
                                lastMessage={chatSession.lastMessage.code ? "Running command" : chatSession.lastMessage.text}
                                lastMessageDate={chatSession.lastMessageDate}
                            />
                        ))}
                    </Nav>
                </SimpleBarReact>

            </div>
            <Row>
                <Col style={{
                    width: '100%',
                }}>
                    <IconButton
                        onClick={()=> setModalShow(true)}
                        variant="falcon-primary"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        style={{
                            width: '100%',
                        }}
                    >
                        Create New Conversation
                    </IconButton>
                </Col>
            </Row>
        </div>
    );
}

export default ChatSessionSidebar;