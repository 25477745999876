import {Modal,Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";
import IconAlert from "../../common/IconAlert";
import {useNavigate} from "react-router-dom";
import StartConversationForm from "./StartConversationForm";
const CreateConversationModal = ({modalShow,setModalShow,baseURL,chatSessions})=>{
    const [modalAlert,setModalAlert] = useState({
        type:"",
        message:""

    });
    const [servers,setServers] = useState([]);
    const navigate = useNavigate()

    const shouldCloseModal = ()=>{
        if ( chatSessions.length === 0 ){
            navigate("/app/dashboard")
        }
        return false
    }
    useEffect( ()=>{
        if ( !modalShow ){
            setModalAlert({
                type:"",
                message:""
            })
        }

        if ( modalShow){
            axios({
                method:"get",
                headers:{
                    "Authorization":`Bearer ${window.localStorage.getItem("chatssh")}`
                },
                url:`${baseURL}/api/myservers`
            }).then( (r)=>{
                let responseMessage = r.data.servers
                if ( !r.data.servers){
                    setModalAlert({
                        type:"danger",
                        message:"You need to pay for a subscription to use this feature. You will be redirected to the payment page in 5 seconds"
                    })
                    setTimeout( ()=>{
                        navigate("/app/purchase")
                    },3000)
                }
                if ( r.data.servers.length === 0){
                    setModalAlert({
                        type:"info",
                        message:"You need to add a server to use this feature. You will be redirected to the add server page in 5 seconds"
                    })
                    setTimeout( ()=>{
                        navigate("/app/add-server")
                    },3000)
                    return
                }
                let servers = r.data.servers
                setServers(servers)
            }).catch( (e)=>{
                console.log(e.response.data)
            })
        }

        console.log("Load servers")
    },[modalShow])
    return (
        <>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(shouldCloseModal())}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Start A Conversation With Your Server</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalAlert.type.length > 0 && <IconAlert variant={modalAlert.type}>{modalAlert.message}</IconAlert>}
                    {servers.length !== 0 && <>

                        <StartConversationForm servers={servers} setModalAlert={setModalAlert} baseURL={baseURL}/>
                    </>

                    }
                </Modal.Body>



                <Modal.Footer>
                    <Button onClick={() => setModalShow(shouldCloseModal())}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default CreateConversationModal;