import AuthCardLayout from "../../layouts/AuthCardLayout";
import PasswordResetForm from "../authentication/PasswordResetForm";
import React from "react";
import TwoFactorAuthFormLayout from "./TwoFactorAuthFormLayout";

const TwoFactorAuthForm = (props)=>{
    return(
        <AuthCardLayout>
            <h3>Please open Authenticator</h3>
            <TwoFactorAuthFormLayout layout="card" baseURL={props.baseURL}  />
        </AuthCardLayout>
    )
}
export default TwoFactorAuthForm;