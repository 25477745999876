import {Col, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ServerTableBody from "./ServerTableBody";
import {v4 as uuid} from "uuid";
import ServerModal from "./ServerModal";
import axios from "axios";
import FalconComponentCard from "../common/FalconComponentCard";
import ActionButton from "../common/ActionButton";
import CardDropdown from "../common/CardDropdown";
const ServerTable = (props) => {

    let [serverUpdate, setServerUpdate] = useState("")
    let [modalState, setModalState] = useState(()=>{
        return {
            show: false,
            serverId: "",
            serverAlias: ""
        }
    })
    const serverStatusRequest = ()=>{
        return axios({
            method: 'get',
            url: props.baseURL + "/dashboard/server-status",
            headers:{
                Authorization: `Bearer ${window.localStorage.getItem("chatssh")}`
            }
        })
    }
    useEffect ( ()=>{

        let io = props.socket

        io.on("serverUpdate",(data)=>{
            setServerUpdate(uuid())
        })

    },[])

    return (
        <>
            <ServerModal
                modalState={modalState}
                setModalState={setModalState}
                serverUpdate={serverUpdate}
                serverStatusRequest={serverStatusRequest}
                baseURL={props.baseURL}
            />
            <Row>
                <Col>
                        <FalconComponentCard.Header
                            title="Servers"
                            noPreview
                        >
                            <p >
                                View Servers Tunnel and SSH connections
                            </p>
                        </FalconComponentCard.Header>
                            <Table responsive >
                                <thead>
                                <tr>
                                    <th scope="col">Alias</th>
                                    <th scope="col">Host</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Tunnel Connection</th>
                                    <th className="text-end" scope="col">Manage</th>
                                </tr>
                                </thead>
                                <ServerTableBody baseURL={props.baseURL} serverUpdate={serverUpdate} setModalState={setModalState} serverStatusRequest={serverStatusRequest}/>
                            </Table>
                </Col>
            </Row>

        </>

    )
}
export default ServerTable;