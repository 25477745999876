import PageHeader from "../common/PageHeader";
import {Card, Table} from "react-bootstrap";
import AllUsersTableBody from "./AllUsersTableBody";
import {useEffect, useState} from "react";
import axios from "axios";

const Payments = (props)=>{

    let [payments,setPayments] = useState([])

    useEffect(()=>{
        axios(props.baseURL + "/admin/purchases",{
            headers:{
                Authorization:`Bearer ${window.localStorage.getItem("chatssh")}`
            },
            method:"POST"
        }).then( (resp)=>{
            if ( resp.data.type === "success" ){
                setPayments(resp.data.purchases)
            }
        }).catch((err)=>{
            console.log(err)
        })

    },[])

    return (
        <>
            <PageHeader
                title="View all Payments"
                description="View all Payments."
                className="mb-3"
                titleTag="h2"
            >
            </PageHeader>
            <Card className="mb-3">
                <Card.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Payer email</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            payments.length > 1 && payments.map( (payment,index)=>{
                                return (
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{payment.payerEmail}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>

                </Card.Body>
            </Card>
        </>
    )
}
export default Payments;