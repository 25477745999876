import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Table} from 'react-bootstrap';
import SaasRevenue from './SaasRevenue';
import DepositeStatus from './DepositeStatus';
import {FaServer}  from "react-icons/fa";
import {AiFillMessage} from "react-icons/ai"
import {BsServer}  from "react-icons/bs";
import {IoServer} from "react-icons/io5";
import axios from "axios";
import ActionButton from "../../common/ActionButton";
import ServerTable from "../../ChatSSHPlugComponents/ServerTable";
const Saas = (props) => {


    let [serversAdded,setServersAdded] = useState(0)
    let [serversInPlan,setServersInPlan] = useState(0)
    let [availablePlugBotMessages, setAvailablePlugBotMessages] = useState(0)
    let [usedPlugBotMessages,setUsedPlugBotMessages] = useState( 0 )
    let [despositStatus,setDespositStatus] = useState({
        activePlan: false,
        expiresIn: "Never",
        Title: "No Plan",
    })

  useEffect(() => {
    let token = window.localStorage.getItem("chatssh")
    axios.post(props.baseURL +"/dashboard", {}, {
      headers: {
        // Include the Bearer token in the Authorization header
        Authorization: `Bearer ${token}`
      }
    })
        .then(response => {
          // Handle the successful response
            if ( response.data.type === "success" ){
                setServersAdded(response.data.info.serversInDB)
                if ( response.data.info.activePlan){
                    setServersInPlan(response.data.info.activePlan.allowedServers)
                }
                if ( response.data.info.activePlan){
                    setDespositStatus({
                        activePlan: true,
                        expiresIn: response.data.info.activePlan.expiresIn,
                        Title: response.data.info.activePlan.Title,
                    })
                    setAvailablePlugBotMessages(response.data.info.availablePlugBotMessages)
                    setUsedPlugBotMessages(response.data.info.usedPlugBotMessages)
                }
            }
        })
        .catch(error => {
          // Handle any errors that occur during the request
          console.error(error);
        });

  }, []);
  return (
    <>
      <Row className="g-3">
        <Col>
          <Row className="g-3">
            <Col md={4} xxl={12}>
              <SaasRevenue icon={<IoServer/>} heading={"Servers Added"} count={serversAdded} />
            </Col>
            <Col md={4} xxl={12}>
              <SaasRevenue icon={<FaServer/>} heading={"Servers In Plan"} count={serversInPlan}/>
            </Col>
              <Col md={4} xxl={12}>
                  <SaasRevenue icon={<AiFillMessage/>} heading={"Available PlugBot Messages"} count={availablePlugBotMessages}/>
              </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-3">
        <Col xxl={9}>
          <DepositeStatus status={despositStatus} />
        </Col>

      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <Card className="bg-light my-3">
            <Card.Body className="p-3">
                <ServerTable socket={props.socket} baseURL={props.baseURL}/>
            </Card.Body>
          </Card>


        </Col>
      </Row>
    </>
  );
};

export default Saas;
