import React, {useContext, useEffect, useState} from 'react';
import {Outlet, Route, Routes,useLocation} from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import CourseProvider from 'components/app/e-learning/CourseProvider';
import Dashboard from "../components/dashboards/saas";
import AddServerForm from "../components/ChatSSHPlugComponents/AddServerForm";
import ChatGPTKey from "../components/ChatSSHPlugComponents/ChatGPTKey";
import Purchase from "../components/ChatSSHPlugComponents/Purchase";
import PurchaseHistory from "../components/ChatSSHPlugComponents/PurchaseHistory";
import ChangePassword from "../components/ChatSSHPlugComponents/ChangePassword";
import axios from "axios";
import Logout from "../components/authentication/card/Logout";
import {Spinner} from "react-bootstrap";
import AllUsers from "../components/ChatSSHPlugComponents/AllUsers";
import Payments from "../components/ChatSSHPlugComponents/Payments";
import TwoFactorAuth from "../components/ChatSSHPlugComponents/2FA";
import ChatSession from "../components/ChatSSHPlugComponents/ChatSession/ChatSession";
const MainLayout = (props) => {
  const { hash, pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true)
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect( ()=>{

    const token = window.localStorage.getItem("chatssh");
    axios.post(props.baseURL + '/dashboard', {

    },{ headers: {
        Authorization: `Bearer ${token}`
      }})
        .then(function(response) {
          if (response.data.type === 'error') {

            window.location.href = window.location.origin + '/';
          }else{

            setIsLoading(false)
          }
        })
        .catch(function(error) {
            if ( error.response.data.message === "OTP_REQUIRED"){
                window.location.href = window.location.origin + '/auth/2fa';
                return
            }
           window.location.href = window.location.origin + '/';
        }).finally(()=>{

    });

  },[])

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (isLoading) {
    return (
        <Spinner
            className="position-absolute start-50"
            animation="grow"
        />
    );
  }
  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical baseURL={props.baseURL} />
      )}
      <ProductProvider>
        <CourseProvider>
          <div className={classNames('content', { 'pb-0': isKanban })}>
            <NavbarTop />
            <Routes>
              <Route path="/dashboard" element={<Dashboard baseURL={props.baseURL} socket={props.socket} />} />
              <Route path={"/add-server"} element={<AddServerForm baseURL={props.baseURL}/>} />
              <Route path={"/chatgpt-key"} element={<ChatGPTKey baseURL={props.baseURL}/>} />
              <Route path={"/purchase"} element={<Purchase baseURL={props.baseURL}/>} />
              <Route path={"/purchase-history"} element={<PurchaseHistory baseURL={props.baseURL}/>} />
              <Route path={"/change-password"} element={<ChangePassword baseURL={props.baseURL}/>} />
              <Route path={"/adminusers"} element={<AllUsers baseURL={props.baseURL}/>} />
              <Route path={"/adminpayments"} element={<Payments baseURL={props.baseURL}/>} />
              <Route path={"/2fa"} element={<TwoFactorAuth baseURL={props.baseURL}  />} />
              <Route path={"/start-chat-session"} element={<ChatSession baseURL={props.baseURL} socket={props.socket} />} />
              <Route path='/logout' element={<Logout baseURL={props.baseURL}/>}/>

            </Routes>
            <Outlet />
            {!isKanban && <Footer />}
          </div>
        </CourseProvider>
      </ProductProvider>
    </div>
  );
};

export default MainLayout;
