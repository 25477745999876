import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Button, Form, Row, Col, Alert} from 'react-bootstrap';
import Divider from 'components/common/Divider';
import axios from "axios";
import qs from "qs";

const RegistrationForm = ({ hasLabel , baseURL}) => {
    // State
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirm_password: ''
    });
    const [alert,setAlert] = useState(()=>{
        return{
            "variant":"",
            "message":""
        }
    })
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    // Handler
    const handleSubmit = e => {
        e.preventDefault();
        setIsLoading(true);
        console.log(formData)
        axios.post(`${baseURL}/user/register`, formData)
            .then(response => {
                if (response.data.type === 'success') {
                    setAlert(()=>{
                        return {
                            "variant":"success",
                            "message":response.data.message + " Redirecting..."
                        }
                    })
                    let searchParams = window.location.search
                    if ( searchParams && searchParams.length > 0){
                        searchParams = searchParams.substring(1,searchParams.length)
                        let queryToJSON = qs.parse(searchParams)
                        if ( queryToJSON.redirect_uri){
                            axios.post( `${baseURL}/user/login`, formData)
                              .then(response => {
                                  if (response.data.type === 'success') {
                                      window.localStorage.setItem('chatssh', response.data.token);
                                      window.location.href = (`${queryToJSON.redirect_uri}?code=${response.data.token}&state=${queryToJSON.state}`)
                                  }
                              })
                        }
                    }else{
                        setTimeout( ()=>{

                            navigate(`/auth/login${window.location.search}`);
                        }, 3000)
                    }
                } else {
                    console.log(response.data.message)
                    setAlert(()=>{
                        return {
                            "variant":"danger",
                            "message":response.data.message
                        }
                    })
                    setIsLoading(false);
                }

            })
            .catch(error => {
                console.log(error.response.data)
                setAlert(()=>{
                    return {
                        "variant":"danger",
                        "message":error.response.data.message
                    }
                })
                setIsLoading(false);

            })
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    return (
        <Form onSubmit={handleSubmit}>
            {alert.message.length >0 && <Alert variant={alert.variant}>{alert.message}</Alert>}
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Email address</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Email address' : ''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="text"
                />
            </Form.Group>

            <Row className="g-2 mb-3">
                <Form.Group as={Col} sm={6}>
                    {hasLabel && <Form.Label>Password</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? 'Password' : ''}
                        value={formData.password}
                        name="password"
                        onChange={handleFieldChange}
                        type="password"
                    />
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                    {hasLabel && <Form.Label>Confirm Password</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? 'Confirm Password' : ''}
                        value={formData.confirm_password}
                        name="confirm_password"
                        onChange={handleFieldChange}
                        type="password"
                    />
                </Form.Group>
            </Row>

            <Form.Group className="mb-4">
                <Button
                    className="w-100"
                    type="submit"
                    disabled={
                        !formData.email ||
                        !formData.password ||
                        !formData.confirm_password ||
                        isLoading
                    }
                >
                    {isLoading ? 'Registering...' : 'Register'}
                </Button>
            </Form.Group>
            <Divider>or</Divider>

            <Button
                className="w-100"
                variant="danger"
                onClick={() => navigate(`/auth/login${window.location.search}`)}
            >
                Login
            </Button>
        </Form>
    );
};

RegistrationForm.propTypes = {
    hasLabel: PropTypes.bool
};

export default RegistrationForm;