import {Button, Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import {useRef, useState} from "react";

const ManualExecute = (props)=>{

    let commandRef = useRef()
    let [buttonDisabled,setButtonDisabled] = useState(false)
    let clickHandler = ()=>{

        setButtonDisabled(true)
        axios(`${props.baseURL}/api/runcommand`, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('chatssh')}`
            },
            data:{
                alias: props.currentServer.alias,
                command: commandRef.current.value
            },
            method: 'POST'
        }).then( (resp)=> {
            props.setAlert(resp.data)
            setButtonDisabled(false)
        }).catch( (err)=>{
            setButtonDisabled(false)
            props.setAlert({
                "type":"error",
                "message":err.response.data.message
            })
        })
    }
    return (
        <Form>
            <Form.Group className="mb-3" controlId="formGroupAlias">
                <Form.Label>Command</Form.Label>
                <Form.Control type="text" placeholder="command" ref={commandRef}  />
            </Form.Group>
            <Row>
                <Col className="text-center">
                    <Button variant="dark" type="button" onClick={clickHandler} disabled={buttonDisabled}>
                        Execute
                    </Button>
                </Col>
            </Row>
        </Form>
    )

}
export default ManualExecute;