
import {useEffect, useState} from "react";
import axios from "axios";
import ActionButton from "../common/ActionButton";
import SoftBadge from "../common/SoftBadge";
import ServerTableActionButton from "./ServerTableActionButton";
import {v4 as uuid} from "uuid";
import {AiFillCheckSquare,AiFillCloseSquare} from "react-icons/ai";
const ServerTableBody = (props)=>{

    let [servers,setServers] = useState([])
    useEffect ( ()=>{

        props.serverStatusRequest().then( (resp)=>{
            console.log(resp.data.servers)
            setServers(resp.data.servers)
        })
    },[props.serverUpdate])
    return (
        <tbody>
        {servers.length > 0 && servers.map( (server)=>{
            return (<tr key={server.key}>
                <td key={uuid()}>{server.alias}</td>
                <td key={uuid()}>{server.host}</td>
                <td key={uuid()}>{server.username}</td>
                <td key={uuid()}>
                    <SoftBadge bg={server.info.type} className='me-2'>
                        {server.info.message}
                    </SoftBadge>
                </td>
                <td key={uuid()} className={"text-center"} >
                    {!server.port && <AiFillCheckSquare style={{ color:"green", fontSize:20 }}/>}
                    {server.port && <AiFillCloseSquare style={{ color:"red", fontSize:20 }}/>}

                </td>
                <td key={uuid()} className="text-end">
                    <ServerTableActionButton serverId={server.id} serverAlias={server.alias} setShowModal={props.setModalState}/>
                </td>
            </tr>)
        })}
        </tbody>
    )

}
export default ServerTableBody;