import illustration1 from 'assets/img/icons/spot-illustrations/3.svg';
import illustration2 from 'assets/img/icons/spot-illustrations/5.png';
import illustration3 from 'assets/img/icons/spot-illustrations/startup.svg';

export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'EASY',
    color: 'danger',
    title: 'Manage your server with ease',
    description:
      'Manage your server with zero effort through our custom built in ChatBot or through the ChatGPT Plugin Store.!',
    image: illustration1
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'DEVELOP',
    color: 'info',
    title: 'Focus on what matters',
    description:
      "Your product isnt a linux server, so why spend time managing it? ChatSSHPlug allows you to focus on what matters, your product.",
    image: illustration2,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'DEPLOY',
    color: 'success',
    title: 'Deploy solution in seconds',
    description:
      'ChatSSHPlug allows you to talk to your server as you would to your IT Team. Deploy your solution in seconds. No more waiting for your server to be ready, just deploy and go',
    image: illustration3
  }
];
