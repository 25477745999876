import Flex from "../../../common/Flex";
import classNames from "classnames";
import Avatar from "../../../common/Avatar";
import ChatMessageOptions from "../../../app/chat/content/ChatMessageOptions";
import FalconLightBoxGallery from "../../../common/FalconLightBoxGallery";
import {Col, Image, Row, Spinner} from "react-bootstrap";
import FalconLightBox from "../../../common/FalconLightBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import chatSSHlogo from "assets/chatsshlogo.png"
import FalconEditor from "../../../common/FalconEditor";

const ChatSessionMessage = ({message,isLeft})=>{

  const toBr = (text) => {
    return text.split("\n").map((str, index) => <React.Fragment key={index}>{str}<br/></React.Fragment>);
  }


    return (

        <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
            {isLeft && <Avatar size="l" className="me-2" src={chatSSHlogo} />}
            <div
                className={classNames('flex-1', {
                    'd-flex justify-content-end': !isLeft
                })}
            >
                <div
                    className={classNames('w-xxl-75', {
                        'w-100': !isLeft
                    })}
                >
                    <Flex
                        alignItems="center"
                        className={classNames('hover-actions-trigger', {
                            'flex-end-center': !isLeft,
                            'align-items-center': isLeft
                        })}
                    >
                            <>
                                <div
                                    className={classNames('p-2 rounded-2 chat-message', {
                                        'bg-200': isLeft,
                                        'bg-primary text-white light': !isLeft
                                    })}
                                >
                                    {(message && message.text) && (
                                        <p
                                            className="mb-0"

                                        >{toBr(message.text)}</p>
                                    )}
                                    {
                                        message && message.code && <>
                                            <FalconEditor
                                                code={`${message.code}`}
                                                language="bash"
                                                hidePreview
                                            />
                                        </>
                                    }
                                    {
                                        message && message.loading
                                        && <>
                                        <Spinner animation="border" variant="primary" />
                                    </>
                                    }

                                </div>
                            </>
                    </Flex>
                </div>
            </div>
        </Flex>
    )

}
export default ChatSessionMessage;