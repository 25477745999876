import { useState, useRef, useEffect } from 'react';
import {Accordion, Alert, Button, Col, Form, Modal, Nav, Row, Tab, Tabs} from "react-bootstrap";
import ServerInfo from "./ServerInfo";
import CommandDisplay from "./CommandDisplay";
import ManualExecute from "./ManualExecute";
import {v4 as uuid} from "uuid"
import TunnelInfoTab from "./TunnelInfoTab";
const ServerModal = (props) => {
    const [currentServer, setCurrentServer] = useState(null);
    const [alert, setAlert] = useState({
        type:"",
        message:""
    });
    useEffect(() => {
        // Make the API request to get the list of servers
            props.serverStatusRequest().then((resp) => {
            // Find the server with the id passed in props.modalState.serverId
            const serverId = props.modalState.serverId;
            const servers = resp.data.servers;
            const serverIndex = servers.findIndex((server) => server.id === serverId);
            if (serverIndex !== -1) {
                setCurrentServer(servers[serverIndex]);
            } else {
                setCurrentServer(null);
            }
        }).catch((err) => {

            console.error(err);
            setCurrentServer(null);

        });
    }, [props.serverUpdate, props.modalState]);

    return (
        <>
            {currentServer && (
                <Modal
                    show={props.modalState.show}
                    onHide={() => {
                        props.setModalState({
                            show: false, serverId: ''
                        })
                        setAlert({
                            "type":"",
                            message: ""
                        })
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Managing <strong>{currentServer.alias}</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Server Info</Nav.Link>
                                        </Nav.Item>
                                        {!currentServer.port &&
                                            <Nav.Item>
                                                <Nav.Link eventKey={"fifth"}>Tunnel Info</Nav.Link>
                                            </Nav.Item>
                                        }
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Commands executed</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Commands Output</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="forth">Execute command</Nav.Link>
                                        </Nav.Item>

                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content>
                                        {alert.type === "success" &&<Alert variant="success">{alert.message}</Alert>}
                                        {alert.type === "error" &&<Alert variant="danger">{alert.message}</Alert>}
                                        {alert.type === "info" &&<Alert variant="info">{alert.message}</Alert>}

                                        <Tab.Pane eventKey="first">
                                            <ServerInfo
                                                baseURL={props.baseURL}
                                                modalState={props.modalState}
                                                currentServer={currentServer}
                                                setCurrentServer={setCurrentServer}
                                                setAlert={setAlert}
                                                key={uuid()}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <CommandDisplay
                                                baseURL={props.baseURL}
                                                currentServer={currentServer}
                                                type={"messageToServer"}
                                                setAlert={setAlert}
                                                setCurrentServer={setCurrentServer}
                                                key={uuid()}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <CommandDisplay key={uuid()} baseURL={props.baseURL}  setCurrentServer={setCurrentServer} currentServer={currentServer} type={"messageFromServer"} setAlert={setAlert}/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="forth">
                                            <ManualExecute baseURL={props.baseURL} currentServer={currentServer} setAlert={setAlert}/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fifth">
                                            <TunnelInfoTab baseURL={props.baseURL} currentServer={currentServer} setAlert={setAlert} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => props.setModalState({show: false, serverId: ''})}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ServerModal;