import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChatContext } from 'context/Context';
import littleGreen from "assets/littleGreen.png"
const ChatSessionThread = ({index,chatSessionId, lastMessageTitle, lastMessage, lastMessageDate , setSelectedChat}) => {

    const handleClick = ()=>{
        setSelectedChat(()=>{
            return chatSessionId
        });
        console.log(chatSessionId);
    }

    return (
        <Nav.Link
            onClick={handleClick}
            eventKey={index}
            className={classNames(`chat-contact hover-actions-trigger p-3`, {
                'read-message': true
            })}
        >

            <Flex>
                <Avatar src={littleGreen} size="xl" />
                <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                    <Flex justifyContent="between">
                        <h6 className="mb-0 chat-contact-title">{lastMessageTitle}</h6>
                        <span className="message-time fs--2">
              {' '}
                            {lastMessageDate ? lastMessageDate : " "}{' '}
            </span>
                    </Flex>
                    <div className="min-w-0">
                        <div className="chat-contact-content pe-3">
                            <p>
                                {lastMessage}
                            </p>
                        </div>
                    </div>
                </div>
            </Flex>
        </Nav.Link>
    );
};



export default ChatSessionThread;
